import { useState } from "react";
import { submitNewsletterSubscription } from "@/components/Newsletter/NewsletterSignUp/api/submit-newsletter-subscription";
import { useSite } from "@/contexts/site/site.context";
import { useReCaptcha } from "@/hooks/recaptcha/use-recaptcha.hook";
import type { Fetchable } from "@/services/utilities/fetchable";
import { initial, pending, failed } from "@/services/utilities/fetchable";

export function useNewsletterSignup(initialState: Fetchable = initial()) {
  const { executeRecaptcha } = useReCaptcha();
  const { domain } = useSite();

  const [submissionState, setSubmissionState] =
    useState<Fetchable>(initialState);

  function reset() {
    setSubmissionState(initial());
  }

  const submit = async (
    email: string,
    consentedTags: ReadonlyArray<string>,
  ) => {
    setSubmissionState(pending());

    const recaptchaToken = await executeRecaptcha(
      `${domain.replace(".", "_")}_newsletter_signup`,
    );

    if (!recaptchaToken) {
      setSubmissionState(failed("No reCAPTCHA token received."));
    }

    setSubmissionState(
      await submitNewsletterSubscription({
        consentedTags,
        email,
        recaptchaToken,
      }),
    );
  };

  return {
    reset,
    submissionState,
    submit,
  };
}
