"use client";

import type { FormEvent, ReactNode } from "react";
import { useRef, useState, useCallback } from "react";
import type { NewsletterModel } from "@/app/(sites)/_api/newsletter/newsletter.types";
import { RegularButton } from "@/components/Button/RegularButton/RegularButton.component";
import { Email } from "@/components/Input/Email/Email.component";
import { Slider } from "@/components/Slider/Slider.component";
import { SliderItem } from "@/components/Slider/SliderItem.component";
import { NewsletterTermsCheckbox } from "@/components/Terms/NewsletterTermsCheckbox/NewsletterTermsCheckbox.component";
import { Translation } from "@/components/Translation/Translation.component";
import { useFormValidation } from "@/hooks/use-form-validation.hook";
type NewsletterSignUpFormProps = {
  readonly model: NewsletterModel;
  readonly isPending: boolean;
  readonly onSubmit: (email: string, consentedTags: ReadonlyArray<string>) => void;
  readonly variant?: "primary" | "accent";
  readonly renderImage: () => ReactNode;
};
export function NewsletterSignUpForm({
  model: {
    description
  },
  isPending,
  onSubmit,
  variant = "primary",
  renderImage
}: NewsletterSignUpFormProps) {
  const [email, setEmail] = useState("");
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const formRef = useRef<HTMLFormElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const {
    isFormValid,
    checkFormValidity
  } = useFormValidation(formRef);
  const {
    isFormValid: isEmailValid,
    checkFormValidity: checkEmailValidity
  } = useFormValidation(emailRef);
  const submit = useCallback((event: FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // Stops the browser from refreshing the page upon submitting the form.

    if (!isFormValid) {
      return;
    }
    const consentedTags = isTermsAccepted ? ["commercial"] : [];
    onSubmit(email, consentedTags);
  }, [isFormValid, onSubmit, email, isTermsAccepted]);
  return <form data-testid="newsletter-sign-up-form" ref={formRef} onSubmit={submit} data-sentry-component="NewsletterSignUpForm" data-sentry-source-file="NewsletterSignUpForm.component.tsx">
      <Slider activeStep={activeStep} data-sentry-element="Slider" data-sentry-source-file="NewsletterSignUpForm.component.tsx">
        <SliderItem className="grid grid-cols-12" isDisabled={activeStep !== 0} data-sentry-element="SliderItem" data-sentry-source-file="NewsletterSignUpForm.component.tsx">
          <div className="relative col-span-full h-20 sm:order-2 sm:col-span-5 sm:h-full xl:hidden">
            {renderImage()}
          </div>

          <div className="col-span-full flex shrink flex-col justify-between p-2 sm:col-span-7 sm:basis-full sm:py-3 xl:col-span-full">
            <div>
              <h1 className="mb-1 text-balance text-2xl font-theme-bold tracking-tight lg:text-3xl">
                <Translation model={{
                da: "Tilmeld dig vores nyhedsbrev",
                de: "Zum Newsletter anmelden",
                en: "Sign up for our newsletter",
                no: "Meld deg på vårt nyhetsbrev",
                sv: "Prenumerera på vårt nyhetsbrev"
              }} data-sentry-element="Translation" data-sentry-source-file="NewsletterSignUpForm.component.tsx" />
              </h1>

              <p className="mb-4 leading-snug">{description}</p>
            </div>

            <div className="flex flex-col gap-y-2">
              <Email defaultValue={email} disabled={isPending} name="email" ref={emailRef} required onChange={event => {
              setEmail(event.target.value);
              checkEmailValidity();
            }} onKeyDown={event => {
              if (event.key === "Enter" && isEmailValid) {
                setActiveStep(previous => previous + 1);
              }
            }} data-sentry-element="Email" data-sentry-source-file="NewsletterSignUpForm.component.tsx" />

              <RegularButton disabled={!isEmailValid} testId="continue-newsletter-button" variant={variant} onClick={() => setActiveStep(previous => previous + 1)} data-sentry-element="RegularButton" data-sentry-source-file="NewsletterSignUpForm.component.tsx">
                <Translation da="Fortsæt" de="Weiter" en="Continue" no="Fortsett" sv="Fortsätt" data-sentry-element="Translation" data-sentry-source-file="NewsletterSignUpForm.component.tsx" />
              </RegularButton>
            </div>
          </div>
        </SliderItem>

        <SliderItem className="flex flex-col justify-between gap-y-2 px-2 py-3" isDisabled={activeStep !== 1} data-sentry-element="SliderItem" data-sentry-source-file="NewsletterSignUpForm.component.tsx">
          <NewsletterTermsCheckbox defaultChecked={isTermsAccepted} isDisabled={isPending} variant={variant} isRequired onChange={event => {
          setIsTermsAccepted(event.target.checked);
          checkFormValidity();
        }} data-sentry-element="NewsletterTermsCheckbox" data-sentry-source-file="NewsletterSignUpForm.component.tsx" />
          <div className="flex gap-1">
            <RegularButton testId="back-newsletter-button" variant="outline" onClick={() => setActiveStep(previous => previous - 1)} data-sentry-element="RegularButton" data-sentry-source-file="NewsletterSignUpForm.component.tsx">
              <Translation da="Tilbage" de="Zurück" en="Back" no="Tilbake" sv="Tillbaka" data-sentry-element="Translation" data-sentry-source-file="NewsletterSignUpForm.component.tsx" />
            </RegularButton>
            <RegularButton className="w-full" disabled={!isFormValid} isPending={isPending} testId="submit-newsletter-button" type="submit" variant={variant} data-sentry-element="RegularButton" data-sentry-source-file="NewsletterSignUpForm.component.tsx">
              <Translation model={{
              da: "Tilmeld",
              de: "Anmelden",
              en: "Subscribe",
              no: "Registrer",
              sv: "Prenumerera"
            }} data-sentry-element="Translation" data-sentry-source-file="NewsletterSignUpForm.component.tsx" />
            </RegularButton>
          </div>
        </SliderItem>
      </Slider>
    </form>;
}