import clsx from "clsx";
import type { ChangeEvent } from "react";
export type CheckboxProps = {
  readonly className?: string;
  readonly isChecked?: boolean;
  readonly isDisabled?: boolean;
  readonly isRequired?: boolean;
  readonly isReadOnly?: boolean;
  readonly id?: string;
  readonly onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  readonly name?: string;
  readonly variant?: "primary" | "accent";
  // eslint-disable-next-line react/boolean-prop-naming -- this is the react name
  readonly defaultChecked?: boolean;
};
export function Checkbox({
  className,
  isChecked,
  isDisabled,
  isReadOnly,
  isRequired,
  id,
  variant = "primary",
  onChange,
  name,
  defaultChecked
}: CheckboxProps) {
  return <input checked={isChecked} className={clsx(className, "h-3 w-3 rounded-sm border-silver transition-all focus:ring-1 focus:ring-offset-0 enabled:cursor-pointer enabled:hover:border-dim disabled:bg-whisper disabled:checked:bg-silver", variant === "primary" && "text-primary", variant === "accent" && "text-accent")} data-testid={id} defaultChecked={defaultChecked} disabled={isDisabled} id={id} name={name} readOnly={isReadOnly} required={isRequired} type="checkbox" onChange={onChange} onInvalid={event => event.preventDefault()} data-sentry-component="Checkbox" data-sentry-source-file="Checkbox.component.tsx" />;
}