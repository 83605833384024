import clsx from "clsx";
import type { ReactNode } from "react";
type SliderItemProps = {
  readonly children: ReactNode;
  readonly className?: string;
  readonly isDisabled?: boolean;
};
export function SliderItem({
  children,
  className,
  isDisabled = false
}: SliderItemProps) {
  return <li className={clsx("shrink-0 grow basis-full", className)}
  // Only way to use the inert prop prior to React 19: https://stackoverflow.com/a/77153892
  {...{
    inert: isDisabled ? "" : undefined
  }} data-sentry-component="SliderItem" data-sentry-source-file="SliderItem.component.tsx">
      {children}
    </li>;
}